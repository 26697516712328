<template>
  <div class="text-center">
    <v-dialog
      v-model="open"
      width="720"
      persistent
      name="dialog_datos_faltantes"
    >
      <v-card>
        <v-form ref="form">
          <v-layout wrap class="pa-0">
            <v-flex xs10 class="text-left ml-8 pt-10" style="z-index: 10">
              <h1 class="global-text5">Datos Faltantes</h1>

              <p class="mt-3 global-subtitle-modal">
                Favor completar los siguientes datos <br />
                para continuar con la cotización.
              </p>
              <v-row dense multi-line>
                <v-col cols="4">
                  <SelectLabel
                    :fields="select_genero"
                    color="#0077c8"
                    :rules="select_genero.rules"
                    :disabled="loading"
                  />
                </v-col>
                <v-col cols="5">
                  <SelectLabel
                    :fields="select_uso_producto"
                    color="#0077c8"
                    :rules="select_uso_producto.rules"
                    :disabled="loading"
                  />
                </v-col>
                <v-col cols="4" v-if="!data?.vehiculo?.nuevo">
                  <v-text-field
                    name="patente"
                    :label="patente.label"
                    v-model="patente.value"
                    v-mask="patente.mask"
                    @keyup="patente.value = patente.value.toUpperCase()"
                    required
                    dense
                    outlined
                    :placeholder="patente.placeholder"
                    :rules="patente.rules"
                  ></v-text-field>
                </v-col>
                <v-col :cols="data?.vehiculo?.nuevo ? 9 : 5">
                  <v-autocomplete
                    label="Comuna"
                    :disabled="loading"
                    :items="comunas"
                    item-text="label"
                    v-model="comunaSelected"
                    outlined
                    dense
                    required
                    return-object
                    placeholder="Seleccione una comuna para continuar"
                    :rules="[(v) => !!v || 'Debe seleccionar una comuna.']"
                  />
                </v-col>
              </v-row>

              <div
                id="ComputerSvg"
                class="ml-0"
                style="align-items: center; justify-content: center"
              >
                <v-img
                  class="image-cuate"
                  width="200px"
                  src="@/assets/modalImages/cuate.png"
                  contain
                />
              </div>
            </v-flex>
            <v-img
              width="450px"
              id="BackgroundSvgs"
              src="@/assets/modalImages/Vector.png"
            />
            <v-flex class="d-flex align-center mb-6" offset-xs3>
              <v-btn
                color="primary"
                class="text-none"
                width="150"
                :loading="loading"
                @click="cotizar"
                >Cotizar <v-icon right>mdi-tag-arrow-right</v-icon></v-btn
              >
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
    </v-dialog>
    <ModalForumError
      :open="openError"
      :error="msgError"
      errorArray="errorArray"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SelectLabel from "@/components/Inputs/SelectLabel.vue";
import ModalForumError from "@/components/Modal/Forum/error.vue";
import { validarFactura } from "@/store/resources/validate.js";
import { patentMask } from "@/helpers/mask.js";

export default {
  props: ["open", "data", "id"],
  data: () => ({
    dialog: true,
    loading: false,
    dataNewQuot: null,
    validateQuot: true,
    msgError: [],
    errorArray: false,
    openError: false,
    modelo_frontier_filtro_uso_producto: process.env.VUE_APP_MODELO_FRONTIER,
    codigo_comercial: "2",
    comunas: [],
    comunaSelected: null,
    select_genero: {
      label: "Género",
      answers: [],
      selected: "",
      placeholder: "Género",
      rules: [(value) => !!value || "Debe seleccionar un genero."],
    },
    select_uso_producto: {
      label: "Uso Producto",
      answers: [],
      selected: "",
      placeholder: "Uso Producto",
      rules: [(value) => !!value || "Debe seleccionar un uso del producto."],
    },
    patente: {
      value: "",
      label: "Patente",
      mask: patentMask,
      placeholder: "Escriba una patente...",
      rules: [
        (value) => validarFactura(value) || "Formato de patente incorrecto",
        (value) => !!value || "El campo patente es requerido.",
      ],
    },
  }),
  methods: {
    ...mapActions("Quot", ["crearCotizacion"]),
    ...mapActions("Proposal", ["getLocations"]),

    async listData(data = null) {
      this.select_uso_producto.answers = data.uso_producto;
      if (data.vehiculo.modelo == this.modelo_frontier_filtro_uso_producto) {
        this.select_uso_producto.answers =
          this.select_uso_producto.answers.filter(
            (value) => value.codigo === this.codigo_comercial
          );
      }
      this.select_genero.answers = data.genero;

      this.comunas = await this.getLocations();
    },
    //TODO: Revisar los datos que se encuentran fijos

    cotizar() {
      if (this.$refs.form.validate()) {
        const objUsoProducto = this.select_uso_producto.answers.filter(
          (lista) => lista.value === this.select_uso_producto.selected
        );
        this.loading = true;
        const ClientType = this.data.cliente.tipo_persona;
        const ClientDataKey = ClientType === "JUR" ? "contacto" : "cliente";
        this.dataNewQuot = {
          numero_cotizacion: this.data.cotizacion.numero_cotizacion,
          producto: this.data.cotizacion.producto, //"62d06cba479fa70014888b7c",
          producto_descripcion: this.data.cotizacion.producto_descripcion, //"AUTOMOVIL",
          nuevo: this.data.vehiculo.nuevo,
          marca: this.data.vehiculo.marca, //5,
          patente: this.data.vehiculo.nuevo ? "FACTURA" : this.patente.value, // "factura"
          modelo: this.data.vehiculo.modelo, //"34",
          anio: this.data.vehiculo.anio, //"2023",

          categoria_descripcion: this.data.cotizacion.categoria_descripcion, //"AUTOMOTRIZ",
          categoria_codigo: this.data.cotizacion.categoria_codigo, //"1",
          categoria: this.data.cotizacion.categoria, //"62d06c7b479fa70014888b65",
          tipo_documento: this.data.cliente[ClientDataKey].tipo_documento, //"RUT",
          numero_documento: this.data.cliente[ClientDataKey].numero_documento, //"142050315",
          cliente: /* "Guillermo Arce Delgado", */ `${this.data.cliente[ClientDataKey].nombre} ${this.data.cliente[ClientDataKey].primer_apellido} ${this.data.cliente[ClientDataKey].segundo_apellido}`,
          primer_apellido:
            /* "Arce", */ this.data.cliente[ClientDataKey].primer_apellido,
          segundo_apellido:
            /* "Delgado", */ this.data.cliente[ClientDataKey].segundo_apellido,
          nombres: /* "Guillermo", */ this.data.cliente[ClientDataKey].nombre,
          fecha_nacimiento:
            /* "1981-06-25", */ this.data.cliente[ClientDataKey]
              .fecha_nacimiento,
          genero: this.select_genero.selected,
          tipo_persona: this.data.cliente.tipo_persona, //"NAT",
          uso_producto: this.select_uso_producto.selected, //"62d06ce4479fa70014888b8a", //TODO: Corresponde al valor de selected
          uso_producto_descripcion: objUsoProducto[0].label,
          uso_producto_codigo: objUsoProducto[0].codigo,

          obj_marca: {
            _id: this.data.vehiculo.obj_marca._id, //"6307f1fe5f842f57f4ad3e82",
            marca: this.data.vehiculo.obj_marca.marca, //5,
            marca_descripcion: this.data.vehiculo.obj_marca.marca_descripcion, //"CHEVROLET"
          },
          obj_modelo: {
            modelo: this.data.vehiculo.obj_modelo.modelo, //"34",
            modelo_descripcion:
              this.data.vehiculo.obj_modelo.modelo_descripcion, //"AVEO"
          },
          id_intension: this.id.idIntension,
          id_transaccion: this.id.idTransaccion,
          concesionario: this.data.concesionario,
          comuna: this.comunaSelected.value,
          comuna_descripcion: this.comunaSelected.label,
          venta_terceros: {
            venta_asistida: false,
            datos_venta_sponsor: this.data.datos_venta_sponsor,
            /* concesionario: 1636,
          concesionario_descripcion: "ALCAR",
          sucursal: 1,
          sucursal_descripcion: "COMANDANTE MALBEC N* 13133",
          vendedor: 6918,
          vendedor_descripcion: "JACQUELINE DE PETRIS POLLONI" */
          },
        };
        this.crearCotizacion(this.dataNewQuot)
          .then((response) => {
            this.dataNewQuot = {};
            const nroCotizacion = response?.cotizacion?.numero_cotizacion;
            if (nroCotizacion) {
              this.$emit("submit", nroCotizacion);
            } else {
              throw "No se ha encontrado el numero de la cotización en la respuesta de Finex.";
            }

            this.validateQuot = !this.validateQuot;
          })
          .catch((e) => {
            const error =
              typeof e === "string"
                ? e
                : e.response?.data ??
                  "Ha ocurrido un error inesperado, intente nuevamente mas tarde.";
            if (Array.isArray(error?.data?.lista_mensaje)) {
              error.data.lista_mensaje.forEach((el) =>
                this.msgError.push(el.mensaje)
              );
            } else {
              this.msgError = [error];
            }

            this.openError = true;
            this.errorArray = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  computed: {
    ...mapGetters("Users", ["getUserInfo", "getBotonColor"]),
    ...mapGetters("Proposal", ["getOffersQuotation"]),
    token() {
      return this.$store.getters.token;
    },
    btnColor() {
      return this.getBotonColor;
    },
    formIsValid() {
      return (
        !this.select_genero.selected ||
        !this.select_uso_producto.selected ||
        !this.comunaSelected
      );
    },
  },
  components: {
    ModalForumError,
    SelectLabel,
  },
};
</script>

<style scoped>
h4 {
  z-index: 10;
}

.image-cuate {
  position: absolute;
  top: 30%;
  right: 5%;
  max-width: 500px;
  z-index: 0;
}

#BackgroundSvgs {
  position: absolute;
  top: 0%;
  right: 0%;
  max-width: 500px;
}

#ComputerSvg {
  z-index: 10;
}

:deep() .v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow: hidden;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
}
</style>
